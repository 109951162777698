<template>
  <Dialog :dialogWidth="1200" :dialog="dialog">
    <template v-slot:title>Create Segments</template>
    <template v-slot:body class="pa-0">
      <v-container v-if="dialog" fluid>
      <!-- <pre>{{ filterLists }}</pre> -->
        <v-form ref="createSegmentForm" v-model.trim="formValid" lazy-validation>
          <template>
            <v-radio-group id="template_type" hide-details dense mandatory row style="width: 100%;" v-model="type">
              <v-col md="3">
                <v-radio value="gold" class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between" label="SMS"
                  :class="type == 'gold' ? 'orange lighten-4' : 'grey lighten-5'" :color="type == 'gold' ? 'orange ' : ''"
                  text-color="white" icon="mdi-message-outline" x-large style="background-color: #f2d8b8; width: auto"
                  :style="[
                    type == 'gold'
                      ? { border: ' 1px solid #e08310 !important' }
                      : { border: '1px solid #ECEFF1 !important' },
                  ]">
                  <template v-slot:label>
                    <div class="d-flex justify-space-between align-center" style="width: 100%">
                      <span class="font-level-3-bold"> GOLD </span>

                    </div>
                  </template>
                </v-radio>
              </v-col>
              <v-col md="3">
                <v-radio value="platinum" class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between" label="SMS"
                  :class="type == 'platinum' ? 'orange lighten-4' : 'grey lighten-5'"
                  :color="type == 'platinum' ? 'orange ' : ''" text-color="white" icon="mdi-message-outline" x-large
                  style="background-color: #f2d8b8; width: auto" :style="[
                    type == 'platinum'
                      ? { border: ' 1px solid #e08310 !important' }
                      : { border: '1px solid #ECEFF1 !important' },
                  ]">
                  <template v-slot:label>
                    <div class="d-flex justify-space-between align-center" style="width: 100%">
                      <span class="font-level-3-bold"> PLATINUM </span>

                    </div>
                  </template>
                </v-radio>
              </v-col>
            </v-radio-group>
          </template>
          <label for="segment_name" class="custom-form-label">Segment Name</label>
          <TextInput dense hideTopMargin hide-details :disabled="pageLoading" :loading="pageLoading" id="segment_name"
            placeholder="Segment Name" :rules="[vrules.required(segmentName, 'Segment Name')]"
            :class="{ required: !segmentName }" v-model="segmentName"></TextInput>
          <label class="mt-4 custom-form-label cyan--text text--lighten-2">Filter (Add up to max 5 filter)
          </label>

          <span>
            <v-row class="px-2" >
              <template v-for="(item, index) in filterLists" >
                <v-col class="filter" :key="index + 2" md="4"  >
                  <AutoCompleteInput class="mt-3" hide-details :loading="pageLoading" :disabled="pageLoading"
                    :key="filterLists.length + index + 2" item-text="name" dense item-value="slug" placeholder="Select"
                    v-model="item.name" :rules="[vrules.required(item.name, 'Filter Type')]"
                    :class="{ required: !item.name }" :items="newFilterLIst(item.name)"
                    @change="filterDropDownChange(item.name, index)"></AutoCompleteInput>
                </v-col>
                <v-col class="filter d-flex" :key="filterLists.length + index + 5" md="8">
                  <AutoCompleteInput class="mt-3" v-if="item.filterType == 'select'" hide-details :loading="pageLoading"
                    dense :disabled="pageLoading" item-text="text" item-value="value"
                    @change="filterChange(item.value, index)" :placeholder="'Select ' + item.placeholder"
                    :items.sync="item.dropdownList" :rules="[vrules.required(item.value, item.placeholder)]"
                    :class="{ required: !item.value }" v-model="item.value"></AutoCompleteInput>
                  <template v-else-if="item.filterType == 'select'">
                    <v-flex md6>
                      <DateRangePicker class="mt-3" hide-details v-on:clear="searchranges()" v-model="item.range"
                    
                        :disabled="pageLoading" :loading="pageLoading" id="item-sale-duration" placeholder="Date Range"
                        clearable></DateRangePicker>
                    </v-flex>

                    
                  </template>
                  <template v-else-if="item.filterType == 'joining'">
                    <v-flex md6>
                      <AutoCompleteInput hide-details class="mt-3 mr-2" dense :loading="pageLoading"
                        :disabled="pageLoading" item-text="text" item-value="value" :items.sync="item.dropdownList"
                        v-model="item.dropdown_value" placeholder="Select Filter"
                        :rules="[vrules.required(item.dropdown_value, 'select compare filter ')]"
                        :class="{ required: !item.dropdown_value }"></AutoCompleteInput>
                    </v-flex>

                    <v-flex md6>
                      <DateRangePicker class="mt-3" hide-details v-on:clear="searchranges()" v-model="item.range"
                        :disabled="pageLoading" :loading="pageLoading" id="item-sale-duration" placeholder="Date Range"
                        :rules="[vrules.required(item.range, 'Date Range ')]"
                        :class="{ required: !item.range }"
                        clearable></DateRangePicker>
                    </v-flex>
                  </template>
                  <template v-else-if="item.filterType == 'dob'">
                    <v-flex md6>
                      <AutoCompleteInput hide-details class="mt-3 mr-2" dense :loading="pageLoading"
                        :disabled="pageLoading" item-text="text" item-value="value" :items.sync="item.dropdownList"
                        v-model="item.dropdown_value" placeholder="Select Filter"
                        :rules="[vrules.required(item.dropdown_value, 'select compare filter ')]"
                        :class="{ required: !item.dropdown_value }"></AutoCompleteInput>
                    </v-flex>

                    <v-flex md6>
                      <DateRangePicker class="mt-3" hide-details v-on:clear="searchranges()" v-model="item.range"
                        :disabled="pageLoading" :loading="pageLoading" id="item-sale-duration" placeholder="Date Range"
                        :rules="[vrules.required(item.range, 'Date Range ')]"
                        :class="{ required: !item.range }"
                        clearable></DateRangePicker>
                    </v-flex>
                  </template>
                  <template v-else-if="item.filterType == 'compare'">
                    <v-flex md6>
                      <AutoCompleteInput hide-details class="mt-3 mr-2" dense :loading="pageLoading"
                        :disabled="pageLoading" item-text="text" item-value="value" :items.sync="item.dropdownList"
                        v-model="item.dropdown_value" placeholder="Select Filter"
                        :rules="[vrules.required(item.dropdown_value, 'select compare filter ')]"
                        :class="{ required: !item.dropdown_value }"></AutoCompleteInput>
                    </v-flex>
                    <v-flex md6>
                      <AutoCompleteInput hide-details class="mt-3 mr-2" dense :loading="pageLoading"
                        :disabled="pageLoading" item-text="text" item-value="value" :items.sync="item.compare_dropdown"
                        v-model="item.condition" placeholder="Select Filter"
                        :rules="[vrules.required(item.dropdown_value, 'select compare filter ')]"
                        :class="{ required: !item.dropdown_value }"></AutoCompleteInput>
                    </v-flex>
                    <v-flex md6>
                      <NumberInput hide-details :loading="pageLoading" :disabled="pageLoading" class="mr-1 mt-3" dense
                        :placeholder="'Enter' + item.placeholder" :rules="[vrules.required(item.value, item.placeholder)]"
                        :class="{ required: !item.value }" v-model.number="item.value"></NumberInput>
                    </v-flex>
                  </template>

                  <AutoCompleteInput v-else hide-details class="mt-3" dense :loading="pageLoading" :disabled="pageLoading"
                    item-text="text" item-value="value" placeholder="select" :items.sync="emptyList"></AutoCompleteInput>
                   <!-- {{ item.condition }}{{ filterProductss }} -->
                      <AutoCompleteInput v-if="item.filterType == 'select'" style="margin-left:10px;" hide-details class="mt-3 mr-5" :loading="pageLoading"
                    :disabled="pageLoading" :key="filterLists.length + index + 2" item-text="text" item-value="value"
                    dense placeholder="Select" v-model="item.condition" :rules="[vrules.required(item.name, 'Filter Type')]"
                    :class="{ required: !item.name }" :items.sync="filterProductss.child"></AutoCompleteInput>
                   
                 

                  <v-btn v-if="filterLists.length > 1" @click="removeFilter(index)" text class="mt-2 ml-1" icon color="red">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                </v-col>


              </template>
            </v-row>
          </span>
        </v-form>
        <v-btn @click="addFilter" depressed label color="blue darken-4"
          class="white--text text--darken-1 custom-form-label mt-2"><v-icon small>mdi-plus</v-icon>Add new filter</v-btn>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn :disabled="pageLoading || !formValid" :loading="pageLoading" class="white--text mr-2" depressed
        color="blue darken-4" tile @click="saveOrUpdate">
        Save
      </v-btn>
      <v-btn :disabled="pageLoading" depressed tile v-on:click="$emit('close', false)">
        Cancel
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { GET_ALL_FILTERS, CREATE_SEGMENT } from "@/core/lib/marketing.lib";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import NumberInput from "@/view/components/NumberInput";
// import SelectInput from "@/view/components/SelectInput";
import { cloneDeep, find, filter } from "lodash";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { GET_SEGMENT } from "@/core/lib/marketing.lib";
import DateRangePicker from "@/view/components/DateRangePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import { mapGetters } from "vuex";
export default {
  name: "Create-segment",
  title: "Segment Create",
  components: { TextInput, Dialog, NumberInput, DateRangePicker, AutoCompleteInput },
  data() {
    return {
      emptyList: [],
      formValid: true,
      newUser: 0,
      range:[],
      dropdownList: [],
      segmentName: null,
      type: null,
      segment_type:null,
      filterProductss: [],
      genderList: [
        {
          text: "Male",
          value: "male",
          type: "select",
          selectList: [
            {
              text: "Male",
              value: "male",
            },
            {
              text: "Female",
              value: "female",
            },
          ],
        },
        {
          text: "Female",
          value: "female",
        },
      ],
      copyFilterList: [],
      filterLists: [
        {
          name: null,
          range:[],

          filter_id: null,
          compare_dropdown: [],
          dropdownList: [],
        },
      ],
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
 
    ...mapGetters(["errors"]),
    // product_list: () => {
    //   return function (id) {
    // let _productList = cloneDeep(this.   );
    //     const newArray = filter(_productList, (row) => {
    //       if (row.value != id) {
    //         if (!find(this.line_items, (inner_row) => inner_row.product == row.id)) {
    //           return row;
    //         }
    //       } else {
    //         return row;
    //       }
    //     });
    //     return newArray;
    //   };
    // },
    dropdown1() {
      let _dropDownList = cloneDeep(this.dropdownList);
      const newArray = filter(_dropDownList, (row) => {
        if (
          !find(this.filterLists, (filter) => {
            // console.log(filter.filter_id);
            // console.log(row.id);
            if (!filter.filter_id == row.id && filter.selectFilter == row.slug) {
              return true;
            } else {
              return false;
            }
          })
        ) {
          return row;
        }
      });
      return newArray;
    },
  },

  methods: {
    filterChange(selectedFilter, index) {
      console.log(selectedFilter, 'selectedFilter')
      console.log(index, 'index')
      if (selectedFilter == "new_users") {
        this.newUser = 1;

      } else if (selectedFilter == "existing_users") {
        this.newUser = 1;

      } else {
        this.newUser = 0;

      }
      this.newSearch = this.filterLists[index]?.dropdownList;
      this.filterProductss = find(this.newSearch, {
        value: selectedFilter,
      });
      console.log(this.filterProductss, 'this.filterProductss')



    },
    async saveOrUpdate() {
      const _this = this;
      // if (!_this.$refs.createSegmentForm.validate()) {
      //   return false;
      // }

      const createSegmentForm = _this.validateForm(_this.$refs.createSegmentForm);
      if (createSegmentForm.length) {
        _this.$store.commit(SET_ERROR, _this.errors.concat(createSegmentForm));
        return false;
      }
      try {
        _this.pageLoading = true;
        let payload = null;
        if (this.$route.params.id) {
          payload = {
            id: Number(this.$route.params.id),
            name: this.segmentName,
            filters: this.filterLists,
            type: this.type,

          };
        } else {
          payload = {
            id: null,
            name: this.segmentName,
            type: this.type,
            

            filters: this.filterLists,
          };
        }

        await CREATE_SEGMENT(payload);
        //   _this.$router.replace({
        //     name: "customer-detail",
        //     params: { id: customer.id },
        //     query: { t: new Date().getTime() },
        //   });
        if (this.$route.params.id) {
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Segment Created Successfully." },
          ]);
        } else {
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Segment Updated Successfully." },
          ]);
          // this.getSegment();
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
        this.$emit("success");
        this.$emit("close", false);
      }
    },
    newFilterLIst(selectedFilter) {
      const dropdownList = cloneDeep(this.dropdownList);
      const filterList = cloneDeep(this.filterLists);
      const newList = filter(dropdownList, (element) => {
        if (element.slug != selectedFilter) {
          if (!find(filterList, (row) => row.name == element.slug)) {
            return element;
          }
        } else {
          return element;
        }
      });
      return newList;
    },
    filterDropDownChange(selectedFilter, index) {
      const currentSelectFilter = this.dropdownList.filter((ele) => ele.slug == selectedFilter)[0];
      this.filterLists.splice(index, 1, {
        name: currentSelectFilter.slug,
        value: null,
        filterType: currentSelectFilter.filter_type,
        filter_id: currentSelectFilter.id,
        compare_dropdown: currentSelectFilter?.compare_dropdown
          ? currentSelectFilter?.compare_dropdown
          : [],
        dropdownList: currentSelectFilter.values ? currentSelectFilter.values : [],
        placeholder: currentSelectFilter.name,
      });
      if (selectedFilter == "new_users") {
        this.newUser = 1;
      } else {
        this.newUser = 0;

      }
    },
    addFilter() {
      this.filterLists.push({
        name: null,
      });
    },
    removeFilter(index) {
      this.filterLists.splice(index, 1);
    },
    async getAllFilter() {
      try {
        const data = await GET_ALL_FILTERS();
        this.dropdownList = data;
        this.copyFilterList = data;
      } catch (error) {
        console.log(error);
      }
    },
    async getSegment() {
      const _this = this;
      try {
        let segments = await GET_SEGMENT(this.$route.params?.id);
        this.segmentName = segments.name;
        this.type = segments.type;
        let newFilterSegments = segments.filters.map((ele) => {
          ele.slug = ele.name;
          return ele;
        });
        
        this.filterLists = newFilterSegments;

        // let newFilterType = find(segments, use (ele)  {
        //   ele.slug = 'customer_type';
        //   return ele;
        // });
        this.filterProducts = find(segments.filters, {
          slug: 'customer_type',
      });
      // this.filterProductss1 = this.filterProducts.dropdownList;
      this.filterProductss = find(this.filterProducts?.dropdownList, {
          value: this.filterProducts?.value,
      });
        
        console.log(this.filterProductss,'fsdff');
        this.segment = segments;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
  },
  async mounted() {
    this.getAllFilter();
    if (this.$route.params?.id && this.$route.name == "segment-detail") {
      await this.getSegment();
    }
  },
};
</script>

<style>
.row.px-2.altclass {
    background-color: #e3eff9;
}
</style>
