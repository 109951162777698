<template>
  <v-text-field
    :id="id"
    ref="numberInput"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :suffix="suffix"
    :hide-details="hideDetails"
    outlined
    :rules="rules"
    class="mt-2 pt-0"
    v-model="numberinput"
    v-mask="mask"
    :append-outer-icon="appendOuterIcon"
    :placeholder="placeholder"
    v-on:click:append-outer="$emit('click:append-outer', true)"
    v-on:change="$emit('change', true)"
    v-on:keyup="$emit('keyup', true)"
  ></v-text-field>
</template>
<script>
export default {
  name: "number-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    mask: {
      type: String,
      default: "########",
    },
    value: {
      type: [String, Number],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberinput: null,
    };
  },
  watch: {
    value(param) {
      this.numberinput = param;
    },
    numberinput() {
      this.$emit("input", this.numberinput);
    },
  },
  mounted() {
    this.numberinput = this.value;
  },
};
</script>
