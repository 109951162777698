<template>
  <Dialog dense :dialog="dialog" :dialogWidth="dialogWidth">
    <template v-slot:title> Redeem Voucher </template>
    <template v-slot:body>
      <v-container>
        <v-layout>
          <v-flex md8 class="mr-4">
            <TextInput
              clearable
              :disabled="pageLoading"
              :loading="loading"
              v-on:click:clear="searchVouchers()"
              v-on:keydown:esc="searchVouchers()"
              v-on:keydown:enter="searchVouchers()"
              v-on:keydown:tab="searchVouchers()"
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              hide-details
              class="my-2"
              placeholder="Search Voucher..."
            >
            </TextInput>
          </v-flex>
        </v-layout>
        <v-row class="vouchers pt-2">
          <template v-if="vouchers.length">
            <v-col md="6" v-for="(row, index) in vouchers" class="p-3" :key="index">
              <div
                class="border-light-grey p-4 link"
                v-on:click="selectVoucher(row)"
                :class="{ 'voucher-selected': row.vcid == vcid }"
              >
                <v-layout>
                  <v-flex md5>
                    <ImageTemplate :src="row.image"></ImageTemplate>
                  </v-flex>
                  <v-flex md7 class="ml-4 my-auto">
                    <p class="m-0 v-name pb-2 black--text">{{ row.name }}</p>
                    <p class="m-0 v-expiry" v-if="row.expiry_date">
                      Expires: {{ formatDate(row.expiry_date) }}
                    </p>
                    <p class="m-0 v-code pt-6 text-center">{{ row.vcode }}</p>
                    <p class="m-0 pb-6 pt-2 v-coupon text-center">COUPON</p>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="text-left">
                    <v-chip class="ma-2" color="green" text-color="white"> Collected </v-chip>
                  </v-flex>
                  <v-flex md4 class="text-center">
                    <v-menu
                      top
                      rounded="0"
                      tile
                      flat
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          class="ma-2"
                          color="orange darken-2"
                          text-color="white"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Detail
                        </v-chip>
                      </template>

                      <v-card rounded="0" tile flat>
                        <v-card-text class="search-cvredeem">
                          <div class="border-bold-grey">
                            <v-chip
                              class="ma-2 search-cvredeem-title"
                              color="blue darken-4"
                              tile
                              text-color="white"
                            >
                              Customer
                            </v-chip>
                            <table width="100%" class="detail-table mt-2">
                              <tr>
                                <th>Customer #</th>
                                <td>
                                  <ValueTemplate
                                    v-model="row.customer_barcode"
                                    title="Customer #"
                                  ></ValueTemplate>
                                </td>
                              </tr>
                              <tr>
                                <th>Name</th>
                                <td>
                                  <ValueTemplate
                                    v-model="row.customer_full_name"
                                    title="Name"
                                  ></ValueTemplate>
                                </td>
                              </tr>
                              <tr>
                                <th>Phone No.</th>
                                <td>
                                  <ValueTemplate
                                    v-model="row.customer_phone_number"
                                    title="Phone No."
                                  ></ValueTemplate>
                                </td>
                              </tr>
                              <tr>
                                <th>Email</th>
                                <td>
                                  <ValueTemplate
                                    class="text-lowercase"
                                    v-model="row.customer_email"
                                    title="Email"
                                  ></ValueTemplate>
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div class="border-bold-grey mt-4">
                            <v-chip
                              class="ma-2 search-cvredeem-title"
                              color="blue darken-4"
                              tile
                              text-color="white"
                            >
                              Order
                            </v-chip>
                            <table width="100%" class="detail-table mt-2">
                              <tr>
                                <th>Order #</th>
                                <td>
                                  <ValueTemplate
                                    v-model="row.order_barcode"
                                    title="Order #"
                                  ></ValueTemplate>
                                </td>
                              </tr>
                              <tr>
                                <th>POS Bill #</th>
                                <td>
                                  <ValueTemplate
                                    v-model="row.order_bill_no"
                                    title="POS Bill #"
                                  ></ValueTemplate>
                                </td>
                              </tr>
                              <tr>
                                <th>Date Time</th>
                                <td>
                                  <ValueTemplate
                                    v-model="row.order_datetime"
                                    datetime
                                    title="Date Time"
                                  ></ValueTemplate>
                                </td>
                              </tr>
                              <tr>
                                <th>Amount</th>
                                <td>
                                  <ValueTemplate
                                    v-model="row.order_amount"
                                    currency
                                    title="Amount"
                                  ></ValueTemplate>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-flex>
                  <v-flex md4 class="text-right">
                    <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip class="ma-2" v-bind="attrs" v-on="on"> Terms of use </v-chip>
                      </template>

                      <v-card flat max-width="400px" rounded="0">
                        <v-card-text flat rounded="0">
                          <label class="btx-label"> TERMS OF USE</label>
                          <p class="light-border-top py-2">{{ row.description }}</p>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </div>
            </v-col>
          </template>
          <v-col v-else md="12" class="text-center">
            <p class="m-0 text-center black--text text-h6">
              <img
                width="40"
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image mr-4"
              />
              Uhh... There are no collected voucher at the moment.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading || !vcid"
        :loading="pageLoading"
        class="white--text mr-2"
        depressed
        color="blue darken-4"
        tile
        v-on:click="saveVoucher()"
      >
        Redeem
      </v-btn>
      <v-btn depressed :disabled="pageLoading" tile v-on:click="closeDialog()"> Cancel </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import ImageTemplate from "@/view/components/Image";
import ValueTemplate from "@/view/components/ValueTemplate";
import { GET_ALL_COLLECTED_VOUCHERS, REDEEM_VOUCHER } from "@/core/lib/common.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
      vouchers: [],
      loading: false,
      pageLoading: false,
      vcid: null,
    };
  },
  methods: {
    closeDialog() {
      this.vcid = null;
      this.search = null;
      this.vouchers = [];
      this.$emit("close", true);
    },
    selectVoucher({ vcid }) {
      this.vcid = vcid;
    },
    async searchVouchers() {
      try {
        if (!this.search) {
          this.$store.commit(SET_ERROR, [{ model: true, message: "Please Search Voucher..." }]);
          return false;
        }
        this.loading = true;
        this.vouchers = await GET_ALL_COLLECTED_VOUCHERS({ search: this.search });
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.loading = false;
      }
    },
    async saveVoucher() {
      if (this.vcid) {
        try {
          await REDEEM_VOUCHER(this.vcid, 0);
          this.closeDialog();
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Voucher Redeemed Successfully" },
          ]);
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        }
      } else {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Please Select Voucher." }]);
      }
    },
  },
  components: {
    Dialog,
    TextInput,
    ImageTemplate,
    ValueTemplate,
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
